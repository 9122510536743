import React, { useEffect } from "react";
import { Location } from "@reach/router";

/* eslint react/prop-types: 0 */
function RedirectComponent({ location }) {
  const { search } = location;
  useEffect(() => {
    const url = decodeURIComponent(atob(search.replace("?url=", "")));
    window.location.href = url;
  }, []);

  return <div />;
}

export default function Out() {
  return (
    <Location>
      {({ location }) => {
        return <RedirectComponent location={location} />;
      }}
    </Location>
  );
}
